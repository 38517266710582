<template>
    <v-text-field v-model="password" v-bind="$attrs" hide-details="auto" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" v-on="$listeners"></v-text-field>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            show: false,
            password: this.$props.value,
        };
    },
    watch: {
        value() {
            this.password = this.$props.value;
        },
    },
};
</script>
