<template>
<v-container fluid fill-height class="console-terms">
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">이용약관</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="termsList" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDate() }}
                </template>
                <template #[`item.terms`]="{ item }">
                    <v-responsive>
                        <v-row @click="$set(item, 'show', !item.show)">
                            <v-col class="text-start">{{ item.subject }}</v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-row v-show="item.show" class="mx-0">
                                <p v-html="item.content"></p>
                            </v-row>
                        </v-expand-transition>
                    </v-responsive>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="edit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="remove(item)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            <v-dialog v-model="dialog" max-width="800px">
                <template #activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right @click="create">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card v-if="dialog && editItem">
                    <v-card-title v-if="editItem._id" class="subtitle-1">약관 수정</v-card-title>
                    <v-card-title v-else class="subtitle-1">약관 등록</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="editItem.order" label="순서" persistent-placeholder hide-details></v-text-field>
                        <v-text-field v-model="editItem.code" label="약관코드" persistent-placeholder hide-details></v-text-field>
                        <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <naver-smarteditor v-model="editItem.content" class="mt-2"></naver-smarteditor>
                        <v-row class="mt-4" justify="center" align="center">
                            <v-col cols="auto" class="py-0 subtitle-1">필수여부</v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="py-0">
                                <v-switch v-model="editItem.necessary" hide-details class="mt-0 pa-0"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        NaverSmarteditor
    },
    created() {
        this.init();
    },
    data: () => {
        return {
            showSearch: false,
            dialog: false,
            page: 1,
            pageCount: 0,
            limit: 10,
            headers: [{
                    text: '순서',
                    align: 'start',
                    width: 70,
                    value: 'order',
                },
                {
                    text: '약관코드',
                    align: 'start',
                    width: 140,
                    value: 'code',
                },
                {
                    text: '이용약관',
                    align: 'center',
                    value: 'terms'
                },
                {
                    text: '작성일자',
                    align: 'center',
                    width: 120,
                    value: 'createdAt'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],
            termsList: [],
            editItem: {
                _id: null,
                order: 1,
                code: null,
                subject: null,
                content: null,
                necessary: true,
            },
            defaultItem: {
                _id: null,
                order: 1,
                code: null,
                subject: null,
                content: null,
                necessary: true,
            }
        };
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            try {
                var { summary, termsList } = await api.console.terms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.termsList = termsList;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);

            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        close() {
            this.dialog = false;
        },

        create() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },

        edit(notification) {
            this.editItem = Object.assign({}, notification);
            this.dialog = true;
        },

        async save() {
            try {
                this.editItem._id ? await api.console.terms.put(this.editItem) : await api.console.terms.post(this.editItem);
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
            this.dialog = false;
        },

        async remove(term) {
            try {
                if(confirm("이용약관을 삭제하시겠습니까?")){
                    await api.console.terms.delete(term);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    }
}
</script>
