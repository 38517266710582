<template>
    <v-container v-if="ready" fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">회원등급</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0"></v-col>
                </v-row>

                <v-data-table :headers="levelsHeaders" :items="levels" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template #item.actions="{ item }">
                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon v-if="item.removable" small @click="remove(item)" class="ml-1">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <v-dialog v-model="dialog" max-width="600px">
                    <template #activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">
                            <span v-if="editItem._id">{{ editItem.name }} 수정</span>
                            <span v-else>신규등급</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.rank" label="등급순위" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.code" label="등급코드" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editItem.name" label="등급명칭" persistent-placeholder dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey" text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
export default {
    props: ["filter"],
    data() {
        return {
            ready: false,
            dialog: false,

            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: false,
            filter: this.$props.filter ?? {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
            },

            ///////////////////////////////////////////////
            // 회원등급 테이블 정보
            ///////////////////////////////////////////////
            limit: 20,
            page: 1,
            pageCount: 0,

            levels: [],
            levelsHeaders: [{
                text: '등급순위',
                align: 'center',
                width: 100,
                value: 'rank'
            }, {
                text: '코드',
                align: 'center',
                width: 140,
                value: 'code'
            }, {
                text: '명칭',
                width: 140,
                align: 'center',
                value: 'name'
            }, {
                text: '적용기간',
                align: 'center',
                width: 100,
                value: 'schedule'
            }, {
                text: 'Actions',
                align: 'center',
                width: 100,
                value: 'actions'
            }],


            ////////////////////////////////////////////////////////////////////
            // /src/models/users/level.js 참고
            ////////////////////////////////////////////////////////////////////
            editItem: null,
            defaultItem: {
                _id: null,
                rank: 999,
                code: "",
                name: "",
                console: {
                    auth: false
                },
                client: {
                    auth: false
                },
                schedule: {
                    enabled: false,
                    price: 0,
                    count: 0,
                },
                joinPoint: {
                    enabled: false,
                    unit: '원',
                    amount: 0
                },
                purchasePoint: {
                    enabled: false,
                    unit: '%',
                    amount: 0
                },
                joinCoupon: {
                    enabled: false,
                    unit: '원',
                    amount: 0,
                    maxPrice: 0,
                    count: 0
                },
                monthlyCoupon: {
                    enabled: false,
                    unit: '원',
                    amount: 0,
                    maxPrice: 0,
                    count: 0
                }
            },

            schedulePrices: [
                { text: "구매없음", value: 0 },
                { text: "5만원이상", value: 50000 },
                { text: "10만원이상", value: 100000 },
                { text: "20만원이상", value: 200000 },
                { text: "30만원이상", value: 300000 },
                { text: "50만원이상", value: 500000 },
                { text: "100만원이상", value: 1000000 },
            ],

            scheduleCounts: [
                { text: "구매없음", value: 0 },
                { text: "구매1회이상", value: 1 },
                { text: "구매2회이상", value: 2 },
                { text: "구매3회이상", value: 3 },
                { text: "구매5회이상", value: 5 },
                { text: "구매10회이상", value: 10 },
                { text: "구매20회이상", value: 20 },
                { text: "구매30회이상", value: 30 },
                { text: "구매50회이상", value: 50 },
            ],

            amountUnits: [ "원", "%" ]
        };
    },
    created(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();

                this.editItem = Object.assign({}, this.defaultItem);
                this.ready = true;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, levels } = await api.console.levels.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.levels = levels;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.defaultItem.rank = this.lastRank + 1;
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },
        edit(level){
            this.editItem = level;
            this.dialog = true;
        },
        async remove(level){
            try{
                if(confirm("해당 등급을 삭제하시겠습니까?")){
                    await api.console.levels.delete(level);
                    await this.search();
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        close(){
            this.dialog = false;
        },

        check(level){
            try{
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(level){
            try{
                if(this.check(level)){
                    level._id ? await api.console.levels.put(level) : await api.console.levels.post(level);
                    await this.search();
                    this.dialog = false;
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async saveSetting(){
            try{
                await api.console.setting.put(this.setting);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        //////////////////////////////////////////////////////
        // 회원등급 변경 예정일자
        //////////////////////////////////////////////////////
        nextScheduledDate(){
            var date = new Date();
            date.addMonth(1);
            date.setDate(1);
            return date.getTime().toDate();
        },
        //////////////////////////////////////////////////////
        // 가장 낮은 회원등급 순위
        //////////////////////////////////////////////////////
        lastRank(){
            var rank = 0;
            for(var i in this.levels){
                if(rank < this.levels[i].rank) rank = this.levels[i].rank;
            }
            return rank;
        }
    }
}
</script>
