<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1440px" width="100%">
                <v-row>
                    <v-col cols="12" md="3">
                        <shop-orders></shop-orders>
                    </v-col>
                    <v-col cols="12" md="3">
                        <shop-shippings></shop-shippings>
                    </v-col>
                    <v-col cols="12" md="3">
                        <shop-claims></shop-claims>
                    </v-col>
                    <v-col cols="12" md="3">
                        <shop-purchases></shop-purchases>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12">
                                <montly-sales></montly-sales>
                            </v-col>
                            <v-col cols="12" md="6">
                                <daily-sales></daily-sales>
                            </v-col>
                            <v-col cols="12" md="6">
                                <daily-purchases></daily-purchases>
                            </v-col>
                            <v-col cols="12" md="6">
                                <daily-customers></daily-customers>
                            </v-col>
                            <v-col cols="12" md="6">
                                <sales-by-dow></sales-by-dow>
                            </v-col>
                            <v-col cols="12">
                                <sales-by-product></sales-by-product>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col cols="12">
                                <shop-inquires></shop-inquires>
                            </v-col>
                            <v-col v-if="scope.includes('console')" cols="12">
                                <center-questions></center-questions>
                            </v-col>
                            <v-col cols="12">
                                <shop-reviews></shop-reviews>
                            </v-col>
                            <v-col v-if="scope.includes('console')" cols="12">
                                <center-notifications></center-notifications>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-responsive>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    computed: {
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
}
</script>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
  width: 100%;
  height: 100%;
}
</style>
