<template>
    <v-dialog v-model="shows" max-width="480">
        <template #activator="{attrs, on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title> 다음 사용자를 탈퇴처리 하시겠습니까? </v-card-title>
            <v-card-subtitle> 탈퇴 처리 시 이전 상태로 되돌이킬 수 없습니다. </v-card-subtitle>
            <v-card-text>
                <v-data-table v-bind="{ headers, items }" mobile-breakpoint="10000" disable-sort hide-default-footer>
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn outlined color="grey" @click="shows = false"> 취소 </v-btn>
                <v-spacer />
                <v-btn color="red darken-1" dark @click="withdraw"> 탈퇴 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
const headers = [
    { value: "username", text: "아이디" },
    { value: "name", text: "기업명/사용자명" },
    { value: "nation", text: "국가", formatter: (value) => value || "-" },
    { value: "phone", text: "연락처" },
    { value: "email", text: "이메일" },
];
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        headers,
    }),
    computed: {
        items() {
            return [this.value];
        },
    },
    methods: {
        async withdraw() {
            const { user: admin } = await api.console.users.get({ _id: this.$store.state.payload._user });
            if (!admin.scope.includes("console")) {
                alert("권한이 없습니다.");
                return;
            }
            const user = await api.console.users.put({
                _id: this.value._id,
                isWithdrawn: true,
                withdrawalReason: "직권처리",
                withdrawalMessage: `처리자: ${admin.username}`,
                withdrawnAt: Date.now(),
            });
            alert(`${this.value.username} 사용자를 탈퇴처리 하였습니다.`);
            this.shows = false;
            this.$emit("input", { ...this.value, ...user });
        },
    },
};
</script>

<style></style>
