<template>
    <component :is="display" />
</template>

<script>
export default {
    computed: {
        scope() {
            return this.$store.state.scope;
        },
        display() {
            if(this.scope.includes("console")) return () => import("./user/UserList.vue");
            return null;
        },
    },
};
</script>
