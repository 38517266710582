var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440px",
      "width": "100%"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('shop-orders')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('shop-shippings')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('shop-claims')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('shop-purchases')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('montly-sales')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('daily-sales')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('daily-purchases')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('daily-customers')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('sales-by-dow')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('sales-by-product')], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-inquires')], 1), _vm.scope.includes('console') ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('center-questions')], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-reviews')], 1), _vm.scope.includes('console') ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('center-notifications')], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }